<template>
    <div class="page">
      <div class="video-wrapper">
        <video id="myVideo" src="" type="video/mp4" ></video>
      </div>
    </div>
  </template>
      
      
      <script>
  export default {
    props: {
      msg: String,
    },
  
    data() {
      return {
        videoStr: "https://video-1316200666.cos.ap-guangzhou.myqcloud.com/fujian/fuan/video/putaoxuanchuan.mp4",
      };
    },
  
    mounted() {
      this.init()
    },
    methods: {
      init() {
        var video = document.getElementById("myVideo");
        var than = this;
      video.addEventListener("ended", function () {
        video.src = than.videoStr;
        video.play();
      });
        video.src = this.videoStr;
        video.play();
      },
    },
  };
  </script>
      
<style>
  html,
  body,
  #app {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  video {
    height: 100%;
    width: 100%;
    position: absolute;
  }
  </style>